import React from "react";
import imageOne from "../../assets/image/inner-page/png/banner-img.png";

const ContentOne = ({ className, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <div className="container">
        {/* Section title */}
        <div className="row align-items-xl-center justify-content-between">
          <div
            className="col-xl-7 col-lg-6 col-md-10"
            data-aos="fade-right"
            data-aos-delay={500}
            data-aos-once="true"
          >
            <h3 className="font-size-10 mb-0 letter-spacing-n83">
              Todo el mundo informado en todo momento
            </h3>
          </div>
          <div
            className="col-xl-5 col-lg-6 col-md-8"
            data-aos="fade-left"
            data-aos-delay={500}
            data-aos-once="true"
          >
            <p className="font-size-7 mb-0 pr-xl-2 pr-lg-5 pr-md-10 pr-sm-20 mt-7 mt-lg-0">
              Evita despistes manteniendo informado a tus empleados/as. Mejora
              la comunicación entre la empresa y el empleado/a.
            </p>
          </div>
        </div>
        {/* End Section title */}
        {/* Banner */}
        <div className="row">
          <div className="col-12">
            <div
              className="pt-10 pt-lg-17 rounded-10"
              data-aos="fade-up"
              data-aos-delay={700}
              data-aos-once="true"
            >
              <img className="w-100" src={imageOne} alt="" />
            </div>
          </div>
        </div>
        {/* End Banner */}
        {/* Services */}
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6 col-xs-8">
            <div
              className="pt-13 pt-lg-20 text-center"
              data-aos="fade-up"
              data-aos-delay={500}
              data-aos-once="true"
            >
              <div className="square-60 bg-blue-3 shadow-dodger-blue-3 rounded-10 text-white font-size-7 mx-auto">
                <i className="fa fa-link" />
              </div>
              <div className="mt-9">
                <h4 className="font-size-7 mb-5">Notificaciones push</h4>
                <p className="font-size-5 mb-0">
                  Envía a tus empleados/as alertas y avisos sobre los cambios
                  que les repercuten. ¡No se perderán nada!
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-xs-8">
            <div
              className="pt-13 pt-lg-20 text-center"
              data-aos="fade-up"
              data-aos-delay={700}
              data-aos-once="true"
            >
              <div className="square-60 bg-red shadow-red rounded-10 text-white font-size-7 mx-auto">
                <i className="fa fa-level-up-alt" />
              </div>
              <div className="mt-9">
                <h4 className="font-size-7 mb-5">Cambios de turno</h4>
                <p className="font-size-5 mb-0">
                  Informa a tus empleados/as en tiempo real cualquier asignación
                  o modificación de sus turnos.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-xs-8">
            <div
              className="pt-13 pt-lg-20 text-center"
              data-aos="fade-up"
              data-aos-delay={900}
              data-aos-once="true"
            >
              <div className="square-60 bg-buttercup shadow-buttercup rounded-10 text-white font-size-7 mx-auto">
                <i className="fa fa-hourglass-half" />
              </div>
              <div className="mt-9">
                <h4 className="font-size-7 mb-5">Olvido de fichajes</h4>
                <p className="font-size-5 mb-0">
                  Notifica a tus empleados/as si se han olvidado de fichar tanto
                  en la entrada como en la salida según el horario asignado para
                  ese día.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6 col-xs-8">
            <div
              className="pt-13 pt-lg-20 text-center"
              data-aos="fade-up"
              data-aos-delay={1100}
              data-aos-once="true"
            >
              <div className="square-60 bg-dark-green shadow-dark-green rounded-10 text-white font-size-7 mx-auto">
                <i className="fa fa-link" />
              </div>
              <div className="mt-9">
                <h4 className="font-size-7 mb-5">
                  Aviso de inicio/fin jornada
                </h4>
                <p className="font-size-5 mb-0">
                  Recuerdales 5 minutos antes de salir o de entrar que deben
                  realizar el fichaje para que no se descontrole tu control de
                  horario.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-xs-8">
            <div
              className="pt-13 pt-lg-20 text-center"
              data-aos="fade-up"
              data-aos-delay={1300}
              data-aos-once="true"
            >
              <div className="square-60 bg-light-orange shadow-light-orange rounded-10 text-white font-size-7 mx-auto">
                <i className="fa fa-level-up-alt" />
              </div>
              <div className="mt-9">
                <h4 className="font-size-7 mb-5">Comunicaciones</h4>
                <p className="font-size-5 mb-0">
                  Comúnicate con toda tu empresa, tus equipos o directamente con
                  tus empleados/as enviándoles una notificación a sus
                  smartphones.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-xs-8">
            <div
              className="pt-13 pt-lg-20 text-center"
              data-aos="fade-up"
              data-aos-delay={1500}
              data-aos-once="true"
            >
              <div className="square-60 bg-purple-heart shadow-purple-heart rounded-10 text-white font-size-7 mx-auto">
                <i className="fa fa-hourglass-half" />
              </div>
              <div className="mt-9">
                <h4 className="font-size-7 mb-5">Documentos</h4>
                <p className="font-size-5 mb-0">
                  Notifica a tus empleados/as cuando adjuntes documentos que
                  requieran ser leídos o ser firmados utilizando nuestra gestión
                  documental.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* End Services */}
      </div>
    </div>
  );
};

export default ContentOne;
