import React from "react";
import imageOne from "../../assets/image/inner-page/png/content-3-img.png";

const ContentTwo = ({ className, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-xl-6 col-lg-7 col-md-9 order-2 order-lg-1">
            <div
              className="mt-13 mt-lg-0"
              data-aos="fade-right"
              data-aos-delay={500}
              data-aos-once="true"
            >
              <h2 className="font-size-10 mb-8 letter-spacing-n83">
                Que la comunicación no sea un problema
              </h2>
              <p className="font-size-7 mb-0">
                Avisa a tus empleados/as de cualquier cambio en su
                planificación, excesos de jornada, aprobación de solicitudes o
                fichajes inválidos e incompletos.
              </p>
              <div className="mt-12">
                <a
                  className="btn btn-blue-3 btn-xl h-55 rounded-5"
                  href="mailto:hola@timeview.io"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Pruébalo gratis
                </a>
              </div>
            </div>
          </div>
          {/* Right Image */}
          <div className="col-xl-5 offset-xl-1 col-lg-5 col-md-8 col-xs-11 order-1 order-lg-2">
            <div
              className="l4-content-img-2 ml-lg-10 ml-xl-7 rounded-10 text-center"
              data-aos="fade-left"
              data-aos-duration={600}
              data-aos-delay={500}
              data-aos-once="true"
            >
              <img className="w-75 w-md-100" src={imageOne} alt="" />
              <div className="image-card w-fit-content bg-white rounded-15 d-flex align-items-center shadow-10 p-8">
                <div className="mr-6 square-59 bg-buttercup-op1 rounded-10 text-buttercup font-size-7">
                  <i className="fas fa-bell" />
                </div>
                <div className="text-left">
                  <h4 className="font-size-6 text-dark-cloud mb-0 line-height-26">
                    Notificación nueva
                  </h4>
                  <p className="font-size-5 text-stone mb-0">
                    Tu jornada termina dentro de 5 minutos
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* End Right Image */}
        </div>
      </div>
    </div>
  );
};

export default ContentTwo;
