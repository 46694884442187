import React from "react";

import PageWrapper from "../../components/PageWrapper";
import Hero from "../../sections/notifications/Hero";
import ContentOne from "../../sections/notifications/ContentOne";
import ContentTwo from "../../sections/notifications/ContentTwo";
import CTA from "../../components/CTA/CTA";

const Notifications = () => {
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName:
            "site-header--menu-right dark-mode-texts dropdown-left",
        }}
      >
        <Hero className="bg-blue-3 position-relative pt-20 pt-sm-24 pt-lg-34 pb-9 pb-lg-32" />
        <CTA className="bg-buttercup py-10" />
        <ContentOne className="pt-13 pt-lg-28 pb-13 pb-lg-26" />
        <ContentTwo className="bg-default-4 pt-15 pt-lg-30 pb-15 pb-lg-26" />
        <CTA className="bg-buttercup py-10" />
      </PageWrapper>
    </>
  );
};
export default Notifications;
